



































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import $ from 'jquery';

@Component({
  components: {
    InsCmsPersonSwiper: () => import('@/components/business/cms/InsCmsPersonSwiper.vue')
  }
})

export default class InsCmsCat2 extends Vue {
  @Prop() private cmsCategoryChilds!: object;
  page: number = 1; // 当前頁
  pageSize: number = 100; // 顯示文章數
  subCatData: object[] = [];
  dealData: object[] = [];
  showPersonList: boolean = false;
  showCatList: boolean = false;
  swiperGroup: number = 0;
  personIndex: number = 0;
  curScrollTop: number = 0;
  index: number = 0;
  timeout:any = null;

  // 获取 Category 和所有 SubCategory 的 cms 列表
  getSubCatContents () {
    this.subCatData = JSON.parse(JSON.stringify(this.cmsCategoryChilds));
    this.$Api.cms
      .getSubCatContents({ CatId: (this as any).subCatData[this.index].Id, Page: this.page, PageSize: this.pageSize, IsMobile: this.isMobile })
      .then(result => {
        this.$set(this.subCatData[this.index], 'CatContents', result.Data);
        this.dealData.push(this.subCatData[this.index]);
        this.$nextTick(() => {
          $('.man-list li').fadeIn(1000);
        });
        console.log(this.dealData, ' 委员会数据');
        this.lazyLoad();
      });
  }
  lazyLoad () {
    $(window).scroll(() => {
      let sTop:any = $(window).scrollTop();
      let winH = $(window).height();
      let docH = Number($('#container').height()) + Number($('#header').height());
      if (sTop + winH >= docH && this.index < this.subCatData.length - 1) {
        if (this.timeout != null) {
          clearTimeout(this.timeout);
        }
        this.timeout = setTimeout(() => {
          this.index += 1;
          this.getSubCatContents();
        }, 50);
      }
    });
  }
  // 点击某个人物
  clickOne (i, j) {
    this.showPersonList = true;
    this.swiperGroup = i;
    this.personIndex = j;
    let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
    this.curScrollTop = -1 * scrollTop;
    $('body').css({ 'position': 'fixed', 'width': '100%', 'top': this.curScrollTop + 'px', 'overflow-y': 'hidden' });
  }

  showSwiper (sta) {
    this.showPersonList = sta;
    $('body').css({ 'position': 'static', 'overflow-y': 'scroll' });
    $(window).scrollTop(-1 * this.curScrollTop);
  }

  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }
  mounted () {
    this.getSubCatContents();
  }
}
